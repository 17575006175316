<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-header">
        <div class="d-flex flex-row justify-content-between align-items-start w-100">
          <div class="card-title">แบนเนอร์</div>
          <div class="d-flex flex-column justify-content-center align-items-end">
            <Create @getGames="getGames" @getProducts="getGameProducts" @submit="upsert"
              :gameCategories="gameCategories" :gameProducts="gameProducts" :games="games" />
          </div>
        </div>
      </div>
      <b-table-simple striped hover small responsive show-empty class="mt-2 position-relative items-center"  style="min-height: 400px;"
        :per-page="perPage" :fields="fields" fixed>
        <b-thead>
          <b-tr>
            <b-th>รูปภาพ</b-th>
            <b-th>แสดง</b-th>
            <b-th>สถานะ</b-th>
            <b-th>#</b-th>
          </b-tr>
        </b-thead>
        <draggable tag="tbody" v-model="items.data" @start="drag = true" @end="dragend">
          <b-tr v-for="item in items.data" :key="item.id">
            <b-td>
              <img :src="item.image && agent ? `${agent.endpoint.slice(0, -4)}/img/banners/${item.image}` : ''"
                alt="Image" width="150">
            </b-td>
            <b-td>{{ item.show === 'INDEX' ? 'หน้าแรก' : 'หน้าเล่น' }}</b-td>
            <b-td><b-form-checkbox v-model="item.status" name="check-button" switch
                @change="changeStatus(item)"></b-form-checkbox></b-td>
            <b-td><b-dropdown id="dropdown-3" text="Success" size="sm" variant="success">
                <template #button-content>
                  <i class="far fa-edit mr-50" /> จัดการ
                </template>

                <Edit @getGames="getGames" @getProducts="getGameProducts" :data="item" @submit="upsert"
                  :gameCategories="gameCategories" :gameProducts="gameProducts" :games="games" />
                <b-dropdown-item @click="destroy(item.id)"><i class="fas fa-trash mr-1 text-danger" /> ลบ
                </b-dropdown-item>
              </b-dropdown></b-td>
          </b-tr>
        </draggable>
      </b-table-simple>
      <div class="card-body d-flex justify-content-between flex-wrap pt-0">
        <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions"
            @change="getData()" />
        </b-form-group>

        <div>
          <b-pagination v-model="page" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0" @input="getItems('')">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormCheckbox, BFormSelect, BFormGroup, BPagination, BDropdownItem, BDropdown, BButton, BTable, BOverlay, BIconController, BFormInput } from 'bootstrap-vue';
import Create from './create.vue';
import Edit from './edit.vue';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import draggable from 'vuedraggable'

export default {
  components: {
    BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormCheckbox, BFormSelect, BFormGroup, BPagination, BDropdownItem, BDropdown, BButton, BTable, BOverlay, BIconController, BFormInput,
    Create, Edit,
    draggable
  },
  computed: {
    agent() {
      return this.$store.state.app.agent
    }
  },
  data: () => ({
    // drag: [],
    isLoading: false,
    perPage: 10,
    pageOptions: [10, 15, 20],
    totalRows: 1,
    page: 1,
    items: {
      data: []
    },
    debouncedSearch: '',
    gameCategories: [],
    gameProducts: [],
    games: []
  }),
  mounted() {
    this.getItems('');
    this.getGameCategories();
  },
  methods: {
    async dragend() {
      this.isLoading = true;
      try {
        await this.$http.post('/banners/drag', { data: this.items.data });

        this.getItems('');
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getItems(term) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get('/banners', {
          params: {
            term: term
          }
        });
        let mapData = data;
        mapData.data = data.data.map(item => {
          item.status = item.status === 1 ? true : false;
          return item;
        })
        this.items = mapData;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getGameCategories() {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get('/games/categories');
        this.gameCategories = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getGameProducts(e) {
      try {
        const { data } = await this.$http.get(`/games/products/${e}`);
        this.gameProducts = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getGames(e) {
      try {
        const { data } = await this.$http.get(`/games/games/${e}`);
        this.games = data;
      } catch (error) {
        console.log(error);
      }
    },
    async upsert(form) {
      this.isLoading = true;

      const fd = new FormData;

      fd.append('id', form.id);
      fd.append('show', form.show);
      fd.append('image', form.file);
      fd.append('game_category_id', form.game_category_id);
      fd.append('game_product_id', form.game_product_id);
      fd.append('game_id', form.game_id);
      // fd.append('status', form.status);

      try {
        const { data } = await this.$http.post('/banners', fd);

        this.getItems('');
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async changeStatus(item) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.post('/banners/changeStatus', item);

        this.getItems('');
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async destroy(id) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.delete(`/banners/${id}`);

        this.getItems('');
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    search: _.debounce(function (val) {
      this.getItems(val);
    }, 400)
  }
}
</script>